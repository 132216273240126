import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { selectEnhancedUser } from "../../store/user/user.selector";

import { sendResetPasswordEmail } from "../../utils/firebase/firebase-auth.utils";
import { deleteUser } from "../../utils/firebase/firebase-functions.utils";
import {
  deleteEntry,
  FIREBASE_COLLECTION_NAMES,
} from "../../utils/firebase/firebase-firestore.utils";

import Paper from "@mui/material/Paper";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";

import DeleteIcon from "@mui/icons-material/Delete";
import LockResetIcon from "@mui/icons-material/LockReset";
import EditIcon from "@mui/icons-material/Edit";

import UserEditDialog from "./user-edit-dialog.component";
import ConfirmDeclineDialog from "../confirm-decline-dialog/confirm-decline-dialog.component";
import React from "react";
import { selectGroups } from "../../store/groups/groups.selector";
import { Box, Chip, Typography } from "@mui/material";
import { selectUsers } from "../../store/users/users.selector";
import { formatTimestamp } from "../../utils/helpers/helpers";
import { getAuth } from "firebase/auth";
import axios from "axios";
import {
  COLOR_PURPLE,
  EMAILER_URL,
  USER_ROLE,
} from "../../utils/helpers/constants";
import UserControllsDrawer from "./user-controll-drawer";

const UserListItem = ({ user }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isResetDialogOpen, setIsResetDialogOpen] = useState(false);

  //Admin users
  const users = useSelector(selectUsers);
  const adminEmails = users
    .filter(
      (user) =>
        user.role === USER_ROLE.ADMIN && user.enableNotification === true
    )
    .map((admin) => admin.notificationEmail);

  //Groups
  const allGroup = useSelector(selectGroups);
  const currentUser = useSelector(selectEnhancedUser);

  const { displayName, email, role } = user;

  const isGroupAssigned = allGroup.some((group) =>
    group.userEmails.includes(email)
  );

  const chipStyle = {
    height: "18px",
    fontSize: "0.6rem",
    borderRadius: "16px",
    padding: "1px 1px",
  };

  const openEditDialog = () => {
    setIsEditDialogOpen(true);
  };

  const closeEditDialog = () => {
    setIsEditDialogOpen(false);
  };

  const openDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const openResetDialog = () => {
    setIsResetDialogOpen(true);
  };

  const closeResetDialog = () => {
    setIsResetDialogOpen(false);
  };

  const resetPassword = async () => {
    try {
      await sendResetPasswordEmail(email);
      closeResetDialog();

      enqueueSnackbar(
        `Password reset email successfully sent to "${user.email}".`,
        { variant: "success" }
      );
    } catch (error) {
      enqueueSnackbar(
        `Failed to reset the password of the user "${user.email}". Please try again`,
        {
          variant: "error",
        }
      );
    }
  };

  const handleDelete = async () => {
    const auth = getAuth();

    try {
      setIsDeleteDialogOpen(false);

      // Auth
      await deleteUser(email);

      // Firestore
      deleteEntry(FIREBASE_COLLECTION_NAMES.USERS, email);

      // Emailer start
      const formattedDate = formatTimestamp({
        seconds: new Date().getTime() / 1000,
        nanoseconds: 0,
      });
      const subject = `"${displayName}" User Deleted`;
      const text =
        `The user "${displayName}" got deleted by ${auth.currentUser.email} at ${formattedDate}.\n \n` +
        `User Name: ${displayName} \n` +
        `Role: ${
          role === USER_ROLE.ADMIN ? USER_ROLE.ADMIN : USER_ROLE.EDITOR
        } \n` +
        `Email Address: ${email}`;
      const recipients = [...adminEmails];

      const emailData = {
        to: recipients,
        subject,
        text,
      };

      if (recipients.length > 0) {
        axios
        .post(EMAILER_URL, emailData)
        .then((response) => {
          console.log("Email sent successfully");
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });
      }
     
      //Emailer end

      enqueueSnackbar(`User "${user.email}" was successfully deleted.`, {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(
        `Failed to delete the user "${user.email}". Please try again.`,
        {
          variant: "error",
        }
      );
      console.log("user deletion failed: ", error);
    }
  };

  return (
    <Paper
      sx={{
        mb: 1,
        borderRadius: "16px",
        minHeight: "72px",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        width: "100%",
        "@media (max-width: 600px)": {
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "8px",
        },
      }}
    >
      <ListItem
        sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}
      >
        {windowWidth > 520 && (
          <ListItemAvatar>
            <Avatar
              style={{
                backgroundColor: `${COLOR_PURPLE}`,
              }}
            >
              {displayName[0]}
            </Avatar>
          </ListItemAvatar>
        )}
        {windowWidth > 520 ? (
          <ListItemText
            sx={{ wordBreak: "break-word" }}
            primary={
              <div style={{ display: "inline-flex", alignItems: "center" }}>
                <span>{displayName}</span>
                {role === USER_ROLE.ADMIN ? (
                  <Chip
                    size="small"
                    label={"Admin"}
                    sx={{
                      ...chipStyle,
                      marginLeft: "10px",
                      color: "white",
                      backgroundColor: "red",
                    }}
                  />
                ) : role === USER_ROLE.EDITOR ? (
                  <Chip
                    size="small"
                    label={"Editor"}
                    sx={{
                      ...chipStyle,
                      marginLeft: "10px",
                      color: "white",
                      backgroundColor: `${COLOR_PURPLE}`,
                    }}
                  />
                ) : (
                  <Chip
                    size="small"
                    label={"Viewer"}
                    color="success"
                    sx={{
                      ...chipStyle,
                      color: "white",
                      marginLeft: "10px",
                    }}
                  />
                )}
              </div>
            }
            secondary={email}
          />
        ) : (
          <Box
            sx={{
              wordBreak: "break-word",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box>
              <Typography>{displayName}</Typography>
            </Box>
            <Box>
              {role === USER_ROLE.ADMIN ? (
                <Chip
                  size="small"
                  label={"Admin"}
                  sx={{
                    ...chipStyle,
                    color: "white",
                    backgroundColor: "red",
                  }}
                />
              ) : role === USER_ROLE.EDITOR ? (
                <Chip
                  size="small"
                  label={"Editor"}
                  sx={{
                    ...chipStyle,
                    color: "white",
                    backgroundColor: `${COLOR_PURPLE}`,
                  }}
                />
              ) : (
                <Chip
                  size="small"
                  label={"Viewer"}
                  color="success"
                  sx={{
                    ...chipStyle,
                    color: "white",
                  }}
                />
              )}
            </Box>
            <Typography color="rgba(0, 0, 0, 0.6)" fontSize="14px">
              {email}
            </Typography>
          </Box>
        )}

        {windowWidth > 900 ? (
          <Box display="flex" gap="10px">
            <IconButton
              onClick={openEditDialog}
              sx={{
                backgroundColor: "#403C8C",
                color: "white",
                ":hover": { color: "#403C8C" },
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={openResetDialog}
              sx={{
                backgroundColor: "#403C8C",
                color: "white",
                ":hover": { color: "#403C8C" },
              }}
            >
              <LockResetIcon />
            </IconButton>
            <IconButton
              color="error"
              disabled={currentUser.email === email || isGroupAssigned}
              onClick={openDeleteDialog}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ) : (
          <Box>
            <UserControllsDrawer
              currentUser={currentUser}
              isGroupAssigned={isGroupAssigned}
              user={user}
              setIsDeleteDialogOpen={setIsDeleteDialogOpen}
              setIsResetDialogOpen={setIsResetDialogOpen}
              setIsEditDialogOpen={setIsEditDialogOpen}
            />
          </Box>
        )}
      </ListItem>
      <UserEditDialog
        open={isEditDialogOpen}
        handleClose={closeEditDialog}
        user={user}
      />
      <ConfirmDeclineDialog
        open={isResetDialogOpen}
        dialogTitle="Reset Password"
        dialogText="Are you sure you want to reset the password?"
        confirmText="Confirm"
        declineText="Cancel"
        handleClose={closeResetDialog}
        handleConfirmAction={resetPassword}
        handleDeclineAction={closeResetDialog}
      />
      <ConfirmDeclineDialog
        open={isDeleteDialogOpen}
        dialogTitle="Delete User"
        dialogText="Are you sure that you want to delete this user? This operation cannot be undone."
        confirmText="Confirm"
        declineText="Cancel"
        handleConfirmAction={handleDelete}
        handleDeclineAction={closeDeleteDialog}
      />
    </Paper>
  );
};

export default UserListItem;
