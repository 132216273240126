// @ts-nocheck
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { addEntry } from "../../utils/firebase/firebase-firestore.utils";
import { FIREBASE_COLLECTION_NAMES } from "../../utils/firebase/firebase-firestore.utils";
import { generateUID } from "../../utils/uid";

import { selectConfigurations } from "../../store/configurations/configurations.selector";
import { selectApps } from "../../store/apps/apps.selector";
import { selectLanguages } from "../../store/languages/languages.selector";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import FormikButton from "../formik-button/formik-button.component";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box } from "@mui/material";
import AddGeneralInfoForm from "./configuration-list-subs/add-general-info-form";
import AddAppsSelection from "./configuration-list-subs/add-apps-selection";
import AddFeaturesAndLanguages from "./configuration-list-subs/add-features-languages";
import {
  filterAndSortApps,
  formatTimestamp,
  sortChips,
} from "../../utils/helpers/helpers";
import { selectGroups } from "../../store/groups/groups.selector";
import { selectUsers } from "../../store/users/users.selector";
import { selectFeatures } from "../../store/features/features.selector";
import NextButton from "./configuration-list-subs/configuration-next-button";
import axios from "axios";
import { getAuth } from "firebase/auth";
import { EMAILER_URL, USER_ROLE } from "../../utils/helpers/constants";

const ConfigurationAddDialog = ({ open, handleClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const formikRef = useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  const steps = ["General", "App", "Features"];
  const maxSteps = steps.length;

  // Fetch data from Redux
  const users = useSelector(selectUsers);
  const allConfiguration = useSelector(selectConfigurations);
  const allApps = useSelector(selectApps);
  const allGroups = useSelector(selectGroups);
  const allLanguages = useSelector(selectLanguages);

  //Admin Emails
  const adminEmails = users
    .filter((user) => user.role === USER_ROLE.ADMIN && user.enableNotification === true)
    .flatMap((user) => user.notificationEmail);

  // State management
  const [appStates, setAppStates] = useState({});

  useEffect(() => {
    if (open && allApps.length > 0) {
      const initialStates = {};
      allApps.forEach((app) => {
        initialStates[app.id] = "disabled";
      });
      setAppStates(initialStates);
    }
  }, [allApps, open]); // Reset app states every time the dialog opens and allApps changes

  // Handle sorted apps using app states
  const sortedApps = useMemo(() => {
    return filterAndSortApps(allApps, appStates);
  }, [allApps, appStates]);

  const appStateEndable = !Object.values(appStates).some((item) => item === "enabled");

  const toggleApps = (appId) => {
    setAppStates((prev) => {
      const newState = { ...prev };
      newState[appId] =
        newState[appId] === "enabled"
          ? "locked"
          : newState[appId] === "locked"
          ? "disabled"
          : "enabled";
      return newState;
    });
  };

  const allGroupsName = useMemo(
    () => allGroups.map((item) => item.groupName),
    [allGroups]
  );
  const allLanguageNames = useMemo(
    () => allLanguages.map((language) => language.languageName),
    [allLanguages]
  );

  const [langInitState, setLangInitState] = useState([]);
  const toggleLang = (langName) => {
    setLangInitState((prev) =>
      prev.includes(langName)
        ? prev.filter((item) => item !== langName)
        : [...prev, langName]
    );
  };

  const sortedLanguages = useMemo(
    () => sortChips(allLanguageNames, langInitState),
    [allLanguageNames, langInitState]
  );
  const disableLang = useMemo(() => langInitState.length < 1, [langInitState]);

  //Features
  const allFeatures = useSelector(selectFeatures);

  const [featureInitState, setFeatureInitState] = useState([]);

  const allFeaturesNames = useMemo(
    () => allFeatures.map((feature) => feature.featureName),
    [allFeatures]
  );

  const toggleFeature = (feature) => {
    setFeatureInitState((prev) =>
      prev.includes(feature)
        ? prev.filter((item) => item !== feature)
        : [...prev, feature]
    );
  };

  const sortedFeatures = useMemo(
    () => sortChips(allFeaturesNames, featureInitState),
    [allFeaturesNames, featureInitState]
  );

  // Form submission
  const handleSubmit = async (values, { resetForm }) => {
    const auth = getAuth();
    
    // Close the dialog immediately
    handleClose();
  
    try {
      const {
        configurationName,
        currency,
        platform,
        screen,
        lifetimePrice,
        monthlyPrice,
        maxTrialPeriod,
        groupName,
      } = values;
  
      const id = generateUID();
  
      // Apps
      const enabledAppIds = [];
      const lockedAppIds = [];
  
      Object.entries(appStates).forEach(([appId, state]) => {
        const app = allApps.find((app) => app.id === appId);
        if (state === "enabled") {
          enabledAppIds.push(app.id);
        } else if (state === "locked") {
          lockedAppIds.push(app.id);
        }
      });
  
      // Groups
      const groupId = allGroups.find((item) => item.groupName === groupName).id;
  
      // Languages
      const languageIds = allLanguages.reduce((acc, language) => {
        if (langInitState.includes(language.languageName)) {
          acc.push(language.id);
        }
        return acc;
      }, []);
  
      // Features
      const enabledFeatureIds = allFeatures
        .filter((item) => featureInitState.includes(item.featureName))
        .map((item) => item.id);
  
      // Add the new configuration entry
      await addEntry(FIREBASE_COLLECTION_NAMES.CONFIGURATIONS, id, {
        id,
        configurationName,
        enabledAppIds,
        lockedAppIds,
        languageIds,
        currency,
        platform,
        screen,
        lifetimePrice,
        monthlyPrice,
        maxTrialPeriod,
        enabledFeatureIds,
        groupId,
      });
  
      // Emailer start
      const selectedGroup = allGroups.find((group) => group.id === groupId);
      const usersGroupEmails = selectedGroup.userEmails;
      const usersEmails = users.filter(user => usersGroupEmails.includes(user.email) && user.enableNotification === true).map(item => item.notificationEmail)
      const formattedDate = formatTimestamp({
        seconds: new Date().getTime() / 1000,
        nanoseconds: 0,
      });
      const enabledApps = allApps
        .filter((app) => enabledAppIds.includes(app.id))
        .map((app) => app.appName);
      const lockedApps = allApps
        .filter((app) => lockedAppIds.includes(app.id))
        .map((app) => app.appName);
      const enabledFeatures = allFeatures
        .filter((feat) => enabledFeatureIds.includes(feat.id))
        .map((feat) => feat.featureName);
  
      const subject = `"${selectedGroup.groupName}" "${configurationName}" Configuration Added`;
      const text =
        `The configuration "${configurationName}" was added by ${auth.currentUser.email} at ${formattedDate}.\n\n ` +
        `Configuration Name: ${configurationName}\n ` +
        `Currency: ${currency}\n ` +
        `Platform: ${platform}\n ` +
        `Screen Size: ${screen}\n ` +
        `Lifetime Price: ${lifetimePrice}\n ` +
        `Monthly Price: ${monthlyPrice}\n ` +
        `Max Trial Period: ${maxTrialPeriod}\n ` +
        `Group Name: ${groupName}\n ` +
        `Enabled Apps: ${enabledApps.join(", ")}\n ` +
        `Locked Apps: ${lockedApps.join(", ")}\n ` +
        `Languages: ${allLanguages
          .filter((lang) => langInitState.includes(lang.languageName))
          .map((lang) => lang.languageCode)
          .join(", ")}\n ` +
        `Features: ${enabledFeatures.join(", ")}\n `;
      const recipients = [...adminEmails, ...usersEmails];
  
      const emailData = {
        to: recipients,
        subject,
        text,
      };
  
      axios
        .post(EMAILER_URL, emailData)
        .then((response) => {
          console.log("Email sent successfully");
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });
      // Emailer end
  
      enqueueSnackbar(
        `Configuration "${configurationName}" was successfully added.`,
        { variant: "success" }
      );
  
      resetForm();
    } catch (error) {
      enqueueSnackbar(
        `Failed to add the configuration. Please try again.`,
        { variant: "error" }
      );
      console.log("adding configuration failed: ", error);
    }
  };
  

  // Reset form on close
  useEffect(() => {
    if (!open && formikRef.current) {
      formikRef.current.resetForm();
      setAppStates([]);
      setLangInitState([]);
      setActiveStep(0);
      setFeatureInitState([]);
    }
  }, [open, allApps, allLanguageNames]);

  const nextStep = async () => {
    const errors = await formikRef.current.validateForm();
    formikRef.current.setTouched(errors);
    if (Object.keys(errors).length === 0) {
      setActiveStep((prev) => prev + 1);
    } else {
      console.log("Validation errors:", errors);
    }
  };

  const FORM_VALIDATION = Yup.object().shape({
    configurationName: Yup.string()
      .test(
        "is-configuration-name-unique",
        "Configuration Name is already used",
        function (value) {
          const groupId = allGroups.find(
            (group) => group.groupName === this.parent.groupName
          )?.id;
          return !allConfiguration.some(
            (config) =>
              config.configurationName === value && config.groupId === groupId
          );
        }
      )
      .required("Required"),
    currency: Yup.string()
      .matches(/^[A-Za-z]+$/, "Must contain only letters")
      .required("Required"),
    screen: Yup.number()
      .typeError("Must be a number")
      .positive("Must be a positive number")
      .required("Required"),
    lifetimePrice: Yup.number()
      .typeError("Must be a number")
      .required("Required")
      .min(0, "Can’t be negative")
      .test("positive", "Can’t be negative", (value) => {
        return value >= 0;
      }),
    monthlyPrice: Yup.number()
      .typeError("Must be a number")
      .required("Required")
      .min(0, "Can’t be negative")
      .test("positive", "Can’t be negative", (value) => {
        return value >= 0;
      }),
    maxTrialPeriod: Yup.number()
      .min(0, "Can’t be negative")
      .required("Required")
      .typeError("Must be a number"),
    groupName: Yup.string().required("Required"),
    platform: Yup.string().required("Required"),
  });

  return (
    <Formik
      initialValues={{
        configurationName: "",
        currency: "",
        screen: "",
        lifetimePrice: "",
        monthlyPrice: "",
        maxTrialPeriod: "",
        groupName: "",
        platform: "",
      }}
      validationSchema={FORM_VALIDATION}
      onSubmit={handleSubmit}
      innerRef={formikRef}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur
    >
      <Form>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
          <DialogTitle>Add New Configuration</DialogTitle>
          <DialogContent>
            <Box
              sx={{
                maxWidth: 400,
                height: 400,
                maxHeight: 400,
                flexGrow: 1,
                "& > * + *": { marginTop: "10px" },
                pt: 2,
              }}
            >
              {activeStep === 0 && (
                <AddGeneralInfoForm allGroups={allGroupsName} />
              )}
              {activeStep === 1 && (
                <AddAppsSelection
                  allAppNames={sortedApps}
                  appStates={appStates}
                  toggleApps={toggleApps}
                />
              )}
              {activeStep === 2 && (
                <AddFeaturesAndLanguages
                  featureInitState={featureInitState}
                  toggleFeature={toggleFeature}
                  languages={sortedLanguages}
                  features={sortedFeatures}
                  langInitState={langInitState}
                  toggleLang={toggleLang}
                />
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button sx={{ color: "red" }} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              onClick={() => setActiveStep((prev) => prev - 1)}
              disabled={activeStep === 0}
              sx={{ color: "#403C8C" }}
            >
              Back
            </Button>
            {activeStep === maxSteps - 1 ? (
              <FormikButton
                type="submit"
                style={{ color: disableLang ? "grey" : "green" }}
                disabled={disableLang}
              >
                Save
              </FormikButton>
            ) : (
              <NextButton
                onClick={nextStep}
                isNextButtonDisabled={false}
                appStateEndable={appStateEndable}
                activeStep={activeStep}
              />
            )}
          </DialogActions>
        </Dialog>
      </Form>
    </Formik>
  );
};

export default ConfigurationAddDialog;
