import React from "react";
import { Box } from "@mui/material";
import FormikTextfield from "../../formik-textfield/formik-textfield.component";
import FormikSelect from "../../formik-select/select.component";
import { PLATFORMS_TYPE } from "../../../utils/helpers/constants";

const AddGeneralInfoForm = ({ allGroups }) => {
  return (
    <>
      <Box mb={2}>
        <FormikTextfield
          name="configurationName"
          fullWidth
          label="Configuration Name"
          variant="standard"
        />
      </Box>
      <Box mb={2}>
        <FormikSelect
          name="groupName"
          label="Group"
          options={allGroups}
        />
      </Box>
      <Box mb={2}>
        <FormikSelect
          name="platform"
          label="Platform"
          options={PLATFORMS_TYPE}
        />
      </Box>
      <Box mb={2}>
        <FormikTextfield
          name="screen"
          fullWidth
          label="Screen Size"
          variant="standard"
        />
      </Box>
      <Box mb={2}>
        <FormikTextfield
          name="lifetimePrice"
          fullWidth
          label="Lifetime Price"
          variant="standard"
        />
      </Box>
      <Box mb={2}>
        <FormikTextfield
          name="monthlyPrice"
          fullWidth
          label="Monthly Price"
          variant="standard"
        />
      </Box>
      <Box mb={2}>
        <FormikTextfield
          name="currency"
          fullWidth
          label="Currency"
          variant="standard"
        />
      </Box>
      <Box mb={2}>
        <FormikTextfield
          name="maxTrialPeriod"
          fullWidth
          label="Trial Period Duration"
          variant="standard"
        />
      </Box>
    </>
  );
};

export default AddGeneralInfoForm;
