import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import {
  deleteEntry,
  FIREBASE_COLLECTION_NAMES,
  updateEntry,
} from "../../utils/firebase/firebase-firestore.utils";

import { selectConfigurations } from "../../store/configurations/configurations.selector";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import LicenseEditDialog from "./license-edit-dialog.component";
import ConfirmDeclineDialog from "../confirm-decline-dialog/confirm-decline-dialog.component";
import { Box, Divider, Paper } from "@mui/material";
import { selectEnhancedUser } from "../../store/user/user.selector";
import LicensePaymentHistory from "./license-list-subs/license-payments/license-payment-history";
import { selectPayments } from "../../store/payments/payments.selector";
import { selectGroups } from "../../store/groups/groups.selector";
import { selectUsers } from "../../store/users/users.selector";

import {
  EmailFormatDayMonthYear,
  formatDate,
  formatTimestamp,
  sendEmailHelper,
} from "../../utils/helpers/helpers";
import LicenseListInfo from "./license-list-subs/license-list-info.component";
import {
  LICENSE_TYPES,
  PAYMENT_TYPE_LIFETIME_ARR,
  PAYMENT_TYPE_SUBSCRIPTION_ARR,
  USER_ROLE,
} from "../../utils/helpers/constants";
import { getAuth } from "firebase/auth";
import LicenseChangeLog from "./license-list-subs/license-changelog/license-changelog-dialog.component";
import { selectLicenseOverviewGroupId } from "../../store/license-overview/license-overview.selector";


const LicenseOverviewListItem = ({ license }) => {
  const { enqueueSnackbar } = useSnackbar();
  
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isOptionsVisible = true;
  const [pauseLicenseText, setPauseLicenseText] = useState("");
  const enableLicense = "Are you sure that you want to enable this license?";
  const disableLicense = "Are you sure that you want to disable this license? Brainssistance will no longer be available on the associated device.";
  
  // Set the initial dialog text based on the license status
  useEffect(() => {
    license.disabled ? setPauseLicenseText(enableLicense) : setPauseLicenseText(disableLicense);
  }, [license.disabled]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDesibleDialogOpen, setIsDisableDialogOpen] = useState(false);
  const [isPaymentHistoryOpen, setIsPaymentHistoryOpen] = useState(false);
  const [isChangeLogOpen, setIsChangeLogOpen] = useState(false);
  const [isUnlinkDialogOpen, setIsUnlinkDialogOpen] = useState(false);

  

  
  
  const allGroups = useSelector(selectGroups);
  const selectedGroupId = useSelector(selectLicenseOverviewGroupId);

  const {
    licenseName,
    id,
    createdAt,
    configurationId,
    type,
    validTill,
    disabled,
    updatedAt,
    comment,
    validFrom,
    adminComment,
  } = license;

  //Admin Users
  const users = useSelector(selectUsers);
  const adminEmails = users
    .filter(
      (user) =>
        user.role === USER_ROLE.ADMIN && user.enableNotification === true
    )
    .map((admin) => admin.notificationEmail);

   

  // Payments
  const payments = useSelector(selectPayments);
  const licensePayments = payments.filter(
    (item) => item.licenseKey === license.licenseKey
  );

  // Adjust current date to be the end of today
  const today = new Date();

  // Check for the most recent payment date
  const isPaymentRequiredChip = () => {
    if (type === LICENSE_TYPES.TRIAL) return false;

    const todayDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );

    if (type === LICENSE_TYPES.SUBSCRIPTION) {
      const subscriptionPayments = licensePayments.filter((payment) =>
        PAYMENT_TYPE_SUBSCRIPTION_ARR.includes(payment.paymentType)
      );

      if (subscriptionPayments.length === 0) return true;

      const latestPayment = new Date(
        Math.max(...subscriptionPayments.map((p) => p.payedTill.seconds * 1000))
      );

      const latestPaymentDate = new Date(
        latestPayment.getFullYear(),
        latestPayment.getMonth(),
        latestPayment.getDate()
      );

      return latestPaymentDate < todayDate;
    }

    if (type === LICENSE_TYPES.LIFETIME) {
      const lifetimePayments = licensePayments.filter((payment) =>
        PAYMENT_TYPE_LIFETIME_ARR.includes(payment.paymentType)
      );

      if (lifetimePayments.length === 0) return true;
    }

    return false;
  };

  const paymentRequired = isPaymentRequiredChip();

  const isValidityNotStartedChip =
    validFrom && new Date(validFrom.seconds * 1000) > today;

  const isExpiredChip = (() => {
    if (type === LICENSE_TYPES.LIFETIME) return false;

    if (validTill && validFrom) {
      const validTillDate = new Date(validTill.seconds * 1000);
      const validFromDate = new Date(validFrom.seconds * 1000);

      // Subtract one day from the validFrom date
      const validFromMinusOneDay = new Date(validFromDate);
      validFromMinusOneDay.setDate(validFromMinusOneDay.getDate() - 1);

      // Check if validTill date is before validFromMinusOneDay
      return validTillDate < validFromMinusOneDay;
    }

    return false;
  })();

  // Enhanced User
  const currentUser = useSelector(selectEnhancedUser);
  const isAdmin = currentUser && currentUser.role === USER_ROLE.ADMIN;

  const isAdminOrEditor = currentUser.role === USER_ROLE.ADMIN || currentUser.role === USER_ROLE.EDITOR

  // Assigned Configuration
  const allConfigurations = useSelector(selectConfigurations);
  const assignedConfiguration = allConfigurations.find(
    (configuration) => configuration.id === configurationId
  );

  const { platform, screen, configurationName } = assignedConfiguration;

  const handleDelete = async () => {
    try {
      const auth = getAuth();
      const lastChangedBy = auth.currentUser
        ? auth.currentUser.email
        : "unknown";

      // Update the license with lastChangedBy before deletion
      await updateEntry(FIREBASE_COLLECTION_NAMES.LICENSES, id, {
        lastChangedBy,
      })
        .then(() => {
          deleteEntry(FIREBASE_COLLECTION_NAMES.LICENSES, id);
        })
        .then(() => {
          const deletePaymentPromises = licensePayments.map((payment) =>
            deleteEntry(FIREBASE_COLLECTION_NAMES.PAYMENTS, payment.id)
          );
          Promise.all(deletePaymentPromises);
        });

      // Delete associated payments

      /// EMAILER START ///
      const selectedGroup = allGroups.find(
        (group) => group.id === selectedGroupId
      );
      const usersGroupEmails = selectedGroup.userEmails;
      const usersEmails = users
        .filter(
          (user) =>
            usersGroupEmails.includes(user.email) &&
            user.enableNotification === true
        )
        .map((item) => item.notificationEmail);
      const formattedDate = formatTimestamp({
        seconds: new Date().getTime() / 1000,
        nanoseconds: 0,
      });
      const licenseConfigurationName = allConfigurations.find(
        (config) => config.id === license.configurationId
      ).configurationName;
      const subject = `"${selectedGroup.groupName}" "${licenseName}" License Deleted`;
      const textStandardUsers =
        `The license "${licenseName}" got deleted by ${auth.currentUser.email} at ${formattedDate}.\n\n ` +
        `License Name: ${licenseName}\n ` +
        `License Key: ${license.licenseKey}\n ` +
        `Type: ${type}\n ` +
        `Configuration Name: ${licenseConfigurationName}\n ` +
        `Valid From: ${EmailFormatDayMonthYear(
          formatTimestamp(validFrom)
        )}\n ` +
        `Valid Till: ${
          type === LICENSE_TYPES.LIFETIME
            ? "N/A"
            : EmailFormatDayMonthYear(formatTimestamp(validTill))
        }\n ` +
        `Comment: ${comment.trim()}\n `;

      const textAdminUsers =
        textStandardUsers + `Admin Comment: ${adminComment.trim()}\n`;

      const filteredUserEmails = usersEmails.filter(
        (email) => !adminEmails.includes(email)
      );

      const emailDataStandardUsers = {
        to: filteredUserEmails,
        subject,
        text: textStandardUsers,
      };

      const emailDataAdminUsers = {
        to: adminEmails,
        subject,
        text: textAdminUsers,
      };

      // Send emails
      if (filteredUserEmails.length > 0) {
        sendEmailHelper(emailDataStandardUsers);
      }

      if (emailDataAdminUsers.to.length > 0)
      sendEmailHelper(emailDataAdminUsers);
      /// EMAILER END ///

      enqueueSnackbar(
        `License "${licenseName}" and its payment history was successfully deleted.`,
        {
          variant: "success",
        }
      );
      setIsDeleteDialogOpen(false);
    } catch (error) {
      enqueueSnackbar(
        `Failed to delete the license "${licenseName}". Please try again.`,
        { variant: "error" }
      );
      console.log("License deletion failed: ", error);
    }
  };

  const handleDisableLicense = async () => {
    const updatedAt = new Date();
    const licenseState = disabled;
    const auth = getAuth();
    const lastChangedBy = auth.currentUser

    // Close the dialog first
    setIsDisableDialogOpen(false);

    try {
      await updateEntry(FIREBASE_COLLECTION_NAMES.LICENSES, license.id, {
        disabled: !disabled,
        updatedAt,
        lastChangedBy: lastChangedBy.email,
      });
      // Update the dialog text state after the dialog is closed
      setPauseLicenseText(!disabled ? enableLicense : disableLicense);

      enqueueSnackbar(
        `License "${licenseName}" was successfully ${
          licenseState ? "enabled" : "disabled"
        }.`,
        {
          variant: "success",
        }
      );
    } catch (error) {
      enqueueSnackbar(
        `Failed to ${
          licenseState ? "enable" : "disable"
        } the license "${licenseName}". Please try again.`,
        { variant: "error" }
      );
      console.log("Editing license failed: ", error);
    }
  };


  const handleUnlinkLicense = async () => {
    if (!isAdminOrEditor) {
      return
    }

    const auth = getAuth();

    if (license.hardwareIds.length === 0) {
      setIsUnlinkDialogOpen(false);
      return;
    }

    try {
      await updateEntry(FIREBASE_COLLECTION_NAMES.LICENSES, license.id, {
        hardwareIds: [],
        lastChangedBy: auth.currentUser.email,
      });

      enqueueSnackbar(`License "${licenseName}" was successfully unlinked.`, {
        variant: "success",
      });

      setIsUnlinkDialogOpen(false);
    } catch (error) {
      // eslint-disable-next-line no-useless-concat
      enqueueSnackbar(
        `Failed to unlink the license "${licenseName}" from its device. Please try again.`,
        {
          variant: "error",
        }
      );
      console.log("unlink license failed: ", error);
    }
  };

  return (
    <Fragment>
      <Paper sx={{ mb: 1, borderRadius: "16px", p: 2, mt: 3 }} elevation={1}>
        <Grid container spacing={2}>
          <LicenseListInfo
            isAdmin={isAdmin}
            isDisabledChip={disabled}
            isExpiredChip={isExpiredChip}
            isOptionsVisible={isOptionsVisible}
            license={license}
            isPaymentRequiredChip={paymentRequired}
            isValidityNotStartedChip={isValidityNotStartedChip}
            setIsDeleteDialogOpen={setIsDeleteDialogOpen}
            setIsDisableDialogOpen={setIsDisableDialogOpen}
            setIsEditDialogOpen={setIsEditDialogOpen}
            setIsPaymentHistoryOpen={setIsPaymentHistoryOpen}
            setIsChangeLogOpen={setIsChangeLogOpen}
            setIsUnlinkDialogOpen={setIsUnlinkDialogOpen}
            isAdminOrEditor={isAdminOrEditor}
          />

          <Grid item xs={12} style={{ paddingTop: "5px" }}>
            <Divider />
          </Grid>

          <Grid item xs={12} md={6} sx={{ pt: 1.5 }}>
            <Typography variant="subtitle1" fontWeight={500}>
              General
            </Typography>
            <Box sx={{ mt: "3px" }}>
              <Box display="grid" gridTemplateColumns="40% 60%">
                <Typography noWrap>Type</Typography>
                <Typography>{type}</Typography>
                <Typography noWrap>Created At</Typography>
                <Typography>
                  {formatDate(new Date(createdAt.seconds * 1000))}
                </Typography>
                {updatedAt && (
                  <>
                    <Typography noWrap>Updated At</Typography>
                    <Typography>
                      {formatDate(new Date(updatedAt.seconds * 1000))}
                    </Typography>
                  </>
                )}
                <Typography noWrap>Valid From</Typography>
                <Typography>
                  {formatDate(new Date(validFrom.seconds * 1000))}
                </Typography>

                {(type === LICENSE_TYPES.SUBSCRIPTION ||
                  type === LICENSE_TYPES.TRIAL) && (
                  <>
                    <Typography noWrap>Valid Till</Typography>
                    <Typography>
                      {formatDate(new Date(validTill.seconds * 1000))}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
            <Grid item xs={12} style={{ paddingTop: "5px" }}>
              <Divider />
            </Grid>
            <Box sx={{ mt: 1.5 }}>
              <Typography variant="subtitle1" fontWeight={500}>
                Configuration
              </Typography>
              <Box sx={{ mt: "3px" }}>
                <Box display="grid" gridTemplateColumns="40% 60%">
                  <Typography noWrap>Name</Typography>
                  <Typography>{configurationName}</Typography>
                  <Typography noWrap>Platform</Typography>
                  <Typography>{platform}</Typography>
                  <Typography noWrap>Screen Size</Typography>
                  <Typography>{`${screen}-inches`}</Typography>
                </Box>
              </Box>
            </Box>

            {comment && (
              <>
                <Grid item xs={12} style={{ paddingTop: "5px" }}>
                  <Divider />
                </Grid>
                <Box sx={{ mt: 1.5 }}>
                  <Typography variant="subtitle1" fontWeight={500}>
                    Comment
                  </Typography>
                  <Box>
                    <Typography
                      sx={{
                        wordBreak: "break-word",
                        maxWidth: "400px",
                        overflow: "auto",
                        maxHeight: "130px",
                      }}
                    >
                      {comment}
                    </Typography>
                  </Box>
                </Box>
              </>
            )}

            {isAdmin && adminComment && (
              <>
                <Grid item xs={12} style={{ paddingTop: "5px" }}>
                  <Divider />
                </Grid>
                <Box sx={{ mt: 1.5 }}>
                  <Typography variant="subtitle1" fontWeight={500}>
                    Admin Comment
                  </Typography>
                  <Box>
                    <Typography
                      sx={{
                        wordBreak: "break-word",
                        maxWidth: "400px",
                        overflow: "auto",
                        maxHeight: "130px",
                      }}
                    >
                      {adminComment}
                    </Typography>
                  </Box>
                </Box>
              </>
            )}
          </Grid>
          {license.hardwareIds && windowWidth < 900 && (
            <Grid item xs={12} style={{ paddingTop: "5px" }}>
              <Divider />
            </Grid>
          )}
          <Grid item xs={12} md={6} sx={{ pt: 1.5 }}>
            <Typography variant="subtitle1" fontWeight={500}>
              Hardware Identifiers
            </Typography>
            <Box sx={{ mt: "3px" }}>
              {platform === "Windows" ? (
                <Box display="grid" columnGap={2} gridTemplateColumns="35% 60%">
                  <Typography noWrap>{"Base Board Serial Number"}</Typography>
                  <Typography
                    sx={{ wordBreak: "break-word", overflowWrap: "break-word" }}
                  >
                    {license.hardwareIds[0] ? license.hardwareIds[0] : "N/A"}
                  </Typography>
                  <Typography noWrap>{"Bios Serial Number"}</Typography>
                  <Typography
                    sx={{ wordBreak: "break-word", overflowWrap: "break-word" }}
                  >
                    {license.hardwareIds[1] ? license.hardwareIds[1] : "N/A"}
                  </Typography>
                  <Typography noWrap>{"Processor ID"}</Typography>
                  <Typography
                    sx={{ wordBreak: "break-word", overflowWrap: "break-word" }}
                  >
                    {license.hardwareIds[2] ? license.hardwareIds[2] : "N/A"}
                  </Typography>
                  <Typography noWrap>{"Video Card ID"}</Typography>
                  <Typography
                    sx={{ wordBreak: "break-word", overflowWrap: "break-word" }}
                  >
                    {license.hardwareIds[3] ? license.hardwareIds[3] : "N/A"}
                  </Typography>
                  <Typography noWrap>{"Windows Serial Number"}</Typography>
                  <Typography
                    sx={{ wordBreak: "break-word", overflowWrap: "break-word" }}
                  >
                    {license.hardwareIds[4] ? license.hardwareIds[4] : "N/A"}
                  </Typography>
                </Box>
              ) : (
                <Typography
                  sx={{ wordBreak: "break-word", overflowWrap: "break-word" }}
                >
                  Hardware ID {license.hardwareIds[0]}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <LicenseEditDialog
        open={isEditDialogOpen}
        handleClose={() => setIsEditDialogOpen(false)}
        license={license}
      />
      <ConfirmDeclineDialog
        open={isDeleteDialogOpen}
        dialogTitle="Delete License"
        dialogText="Are you sure that you want to delete this license and its payment history? This operation cannot be undone."
        confirmText="Confirm"
        declineText="Cancel"
        handleClose={() => setIsDeleteDialogOpen(false)}
        handleConfirmAction={handleDelete}
        handleDeclineAction={() => setIsDeleteDialogOpen(false)}
      />
      <ConfirmDeclineDialog
        open={isDesibleDialogOpen}
        dialogTitle={pauseLicenseText === disableLicense ? "Disable License" : "Enable License"}
        dialogText={pauseLicenseText}
        confirmText="Confirm"
        declineText="Cancel"
        handleClose={() => setIsDisableDialogOpen(false)}
        handleConfirmAction={handleDisableLicense}
        handleDeclineAction={() => setIsDisableDialogOpen(false)}
      />
      <ConfirmDeclineDialog
        open={isUnlinkDialogOpen}
        dialogTitle={"Unlink License"}
        dialogText={
          "Are you sure that you want to unlink the license from the current device? The device will lose access to the app. The action cannot be undone without access to the device."
        }
        confirmText="Confirm"
        declineText="Cancel"
        handleClose={() => setIsUnlinkDialogOpen(false)}
        handleConfirmAction={handleUnlinkLicense}
        handleDeclineAction={() => setIsUnlinkDialogOpen(false)}
      />
      <LicensePaymentHistory
        open={isPaymentHistoryOpen}
        dialogTitle="Payments"
        dialogData={licensePayments}
        declineText="Close"
        handleClose={() => setIsPaymentHistoryOpen(false)}
        handleDeclineAction={() => setIsPaymentHistoryOpen(false)}
        currentUser={currentUser}
        type={type}
        license={license}
      />
      <LicenseChangeLog
        open={isChangeLogOpen}
        dialogTitle="Changelog"
        declineText="Close"
        handleClose={() => setIsChangeLogOpen(false)}
        handleDeclineAction={() => setIsChangeLogOpen(false)}
        currentUser={currentUser}
        license={license}
      />
    </Fragment>
  );
};

export default LicenseOverviewListItem;
