import { useState, Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { selectEnhancedUser } from "../../store/user/user.selector";
import { signOutUser } from "../../utils/firebase/firebase-auth.utils";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import MenuIcon from "@mui/icons-material/Menu";
import useHideOnScroll from "./useHideOnScroll";
import { USER_ROLE } from "../../utils/helpers/constants";


const drawerWidth = 240;

const Header = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const hide = useHideOnScroll();
  const navigate = useNavigate();

  const currentUser = useSelector(selectEnhancedUser);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSignOut = async () => {
    try {
      await signOutUser();
      navigate("/");
    } catch (error) {
      enqueueSnackbar("Sign out failed. Please try again.", {
        variant: "error",
      });
      console.log("sign out failed: ", error);
    }
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }} >
      <Typography variant="h6" sx={{ my: 2 }}>
        LICENSE MANAGEMENT
      </Typography>
      <Divider />
      {currentUser ? (
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate("user/licenses")}>
              <ListItemText primary="Licenses" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate("user/configurations")}>
              <ListItemText primary="Configurations" />
            </ListItemButton>
          </ListItem>
          {currentUser.role === USER_ROLE.ADMIN ? (
            <Fragment>
              <ListItem disablePadding>
                <ListItemButton onClick={() => navigate("admin/apps")}>
                  <ListItemText primary="Apps" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={() => navigate("admin/features")}>
                  <ListItemText primary="Features" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={() => navigate("admin/languages")}>
                  <ListItemText primary="Languages" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={() => navigate("admin/groups")}>
                  <ListItemText primary="Groups" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={() => navigate("admin/users")}>
                  <ListItemText primary="Users" />
                </ListItemButton>
              </ListItem>
            </Fragment>
          ) : null}
          <ListItem disablePadding>
            <ListItemButton onClick={handleSignOut}>
              <ListItemText primary="Sign Out" />
            </ListItemButton>
          </ListItem>
        </List>
      ) : null}
    </Box>
  );

  const container =
    props.window !== undefined ? () => props.window().document.body : undefined;

  return (
    <Box>
      <AppBar
        component="nav"
        position="fixed" style={{ transform: hide ? 'translateY(-100%)' : 'translateY(0)', transition: 'transform 0.3s ease-in-out' }}
        sx={{ backgroundColor: "#403C8C" }}
      >
        <Toolbar>
          {currentUser ? (
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: windowWidth < 1025 ? "block" : "none" }}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <Typography
              sx={{ flexGrow: 1, display: { xs: "block", sm: "none" } }}
            >
              LICENSE MANAGEMENT
            </Typography>
          )}

          <Box display="flex"  justifyContent="space-between" width="100%">
            <Box display="flex" justifyContent="center" gap="20px">
            <img
                style={windowWidth < 600 ? { width: "30px", height: "30px" } : { width: "40px", height: "40px" }}
                alt="logo"
                src="/LogoBrainssistanceWhite.png"
              />
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, alignSelf: "center", display: { xs: "none", sm: "block" } }}
              >
                LICENSE MANAGEMENT
              </Typography>
            </Box>

            {currentUser ? (
              <Box sx={{ display: windowWidth > 1025 ? "flex" : "none"
               , textAlign: "center", alignItems: "center" }}>
                <Button
                  sx={{ color: "#fff", pb: "5px" }}
                  onClick={() => navigate("user/licenses")}
                >
                  Licenses
                </Button>
                <Button
                  sx={{ color: "#fff", pb: "5px" }}
                  onClick={() => navigate("user/configurations")}
                >
                  Configurations
                </Button>
                {currentUser.role === USER_ROLE.ADMIN ? (
                  <Fragment>
                    <Button
                      sx={{ color: "#fff", pb: "5px" }}
                      onClick={() => navigate("admin/apps")}
                    >
                      Apps
                    </Button>
                    <Button
                      sx={{ color: "#fff", pb: "5px" }}
                      onClick={() => navigate("admin/features")}
                    >
                      Features
                    </Button>
                    <Button
                      sx={{ color: "#fff", pb: "5px" }}
                      onClick={() => navigate("admin/languages")}
                    >
                      Languages
                    </Button>
                    <Button
                      sx={{ color: "#fff", pb: "5px" }}
                      onClick={() => navigate("admin/groups")}
                    >
                      Groups
                    </Button>
                    <Button
                      sx={{ color: "#fff", pb: "5px" }}
                      onClick={() => navigate("admin/users")}
                    >
                      Users
                    </Button>
                  </Fragment>
                ) : null}

                <Button sx={{ color: "#fff", pb: "5px" }} onClick={handleSignOut}>
                  Sign Out
                </Button>
              </Box>
            ) : null}
          </Box>
        </Toolbar>
      </AppBar>

      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
          display: windowWidth < 1025 ? "block" : "none" ,
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

export default Header;
