import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import dayjs from "dayjs";
import * as Yup from "yup";

import { selectLicenseOverviewGroupId } from "../../store/license-overview/license-overview.selector";

import { addEntry } from "../../utils/firebase/firebase-firestore.utils";
import { FIREBASE_COLLECTION_NAMES } from "../../utils/firebase/firebase-firestore.utils";

import { generateLicenseKey } from "../../utils/license-key";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";

import FormikButton from "../formik-button/formik-button.component";
import FormikTextfield from "../formik-textfield/formik-textfield.component";
import FormikDateField from "../formik-date/formik-date-picker.component";
import { selectUsers } from "../../store/users/users.selector";
import { selectEnhancedUser } from "../../store/user/user.selector";
import { selectConfigurations } from "../../store/configurations/configurations.selector";
import { selectLicenses } from "../../store/licenses/licenses.selector";

import FormikSelect from "../formik-select/select.component";
import { selectGroups } from "../../store/groups/groups.selector";
import {
  LICENSE_TYPES,
  LICENSE_TYPES_ARRAY,
  USER_ROLE,
} from "../../utils/helpers/constants";
import { generateUID } from "../../utils/uid";
import { getAuth } from "firebase/auth";
import {
  formatDate,
  formatTimestamp,
  sendEmailHelper,
} from "../../utils/helpers/helpers";

const LicenseAddDialog = ({ open, handleClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  //Admin Users
  const users = useSelector(selectUsers);
  const isAdmin = useSelector(selectEnhancedUser).role === USER_ROLE.ADMIN;
  const adminEmails = users
    .filter((user) => user.role === USER_ROLE.ADMIN && user.enableNotification === true)
    .map((admin) => admin.notificationEmail);

  const allConfiguration = useSelector(selectConfigurations);
  const selectedGroupId = useSelector(selectLicenseOverviewGroupId);

  //avalible config
  const configurationsForSelectedGroup = allConfiguration
    .filter((config) => config.groupId === selectedGroupId)
    .map((item) => item.configurationName);
  const avalibleConfigIds = allConfiguration
    .filter((config) => config.groupId === selectedGroupId)
    .map((config) => config.id);

  const allLicenses = useSelector(selectLicenses);
  const allLicensesForSelectedGroup = allLicenses.filter((license) =>
    avalibleConfigIds.includes(license.configurationId)
  );

  //Groups
  const allGroups = useSelector(selectGroups);

  //Licence Key Generator
  const licenseKey = generateLicenseKey();

  const handleSubmit = async (values, { resetForm }) => {
    const auth = getAuth();
    const lastChangedBy = auth.currentUser ? auth.currentUser.email : "unknown";

    const {
      licenseName,
      licenseType,
      licenseConfigurationName,
      licenseValidTill,
      licenseValidFrom,
      comment,
      adminComment,
    } = values;

    // Close the dialog immediately
    handleClose();

    try {
      const id = generateUID();
      const createdAt = new Date();

      // Configuration
      const selectedConfiguration = allConfiguration
        .filter((config) => config.groupId === selectedGroupId)
        .find(
          (config) => config.configurationName === licenseConfigurationName
        );

      const licenseValidDate =
        licenseType === LICENSE_TYPES.LIFETIME
          ? null
          : dayjs(licenseValidTill).toDate();

      const licenseValidFromDate = dayjs(licenseValidFrom).toDate();

      await addEntry(FIREBASE_COLLECTION_NAMES.LICENSES, id, {
        id,
        lastChangedBy,
        licenseKey,
        licenseName,
        type: licenseType,
        configurationId: selectedConfiguration.id,
        validTill: licenseValidDate,
        validFrom: licenseValidFromDate,
        comment: comment.trim(),
        createdAt,
        disabled: false,
        hardwareIds: [],
        adminComment: adminComment.trim(),
      });

      /// EMAILER START ///
      const selectedGroup = allGroups.find(
        (group) => group.id === selectedGroupId
      );

      if (!selectedGroup) {
        console.error("Selected group not found");
        return;
      }

      const usersGroupEmails = selectedGroup.userEmails;
      const usersEmails = users
        .filter(
          (user) =>
            usersGroupEmails.includes(user.email) &&
            user.enableNotification === true
        )
        .map((item) => item.notificationEmail);

      const formattedDate = formatTimestamp({
        seconds: new Date().getTime() / 1000,
        nanoseconds: 0,
      });

      const subject = `"${selectedGroup.groupName}" "${licenseName}" License Added`;

      const textStandardUsers =
        `The license "${licenseName}" got added by ${auth.currentUser.email} at ${formattedDate}.\n\n` +
        `License Name: ${licenseName}\n` +
        `License Key: ${licenseKey}\n` +
        `Type: ${licenseType}\n` +
        `Configuration Name: ${licenseConfigurationName}\n` +
        `Valid From: ${formatDate(licenseValidFromDate)}\n` +
        `Valid Till: ${
          licenseType === LICENSE_TYPES.LIFETIME
            ? "N/A"
            : formatDate(licenseValidDate)
        }\n` +
        `Comment: ${comment.trim()}\n`;

      const textAdminUsers =
        textStandardUsers + `Admin Comment: ${adminComment.trim()}\n`;

      const filteredUserEmails = usersEmails.filter(
        (email) => !adminEmails.includes(email)
      );

      const emailDataStandardUsers = {
        to: filteredUserEmails,
        subject,
        text: textStandardUsers,
      };

      const emailDataAdminUsers = {
        to: adminEmails,
        subject,
        text: textAdminUsers,
      };

      // Send emails
      if (filteredUserEmails.length > 0) {
        sendEmailHelper(emailDataStandardUsers);
      }
      if (emailDataAdminUsers.to.length > 0) {
        sendEmailHelper(emailDataAdminUsers);
      }
      
      /// EMAILER END ///

      enqueueSnackbar(`License "${licenseName}"  was successfully added.`, {
        variant: "success",
      });

      resetForm();
    } catch (error) {
      enqueueSnackbar(
        `Failed to add the license "${licenseName}". Please try again.`,
        { variant: "error" }
      );
      console.log("adding license failed: ", error);
    }
  };

  const licenseTypes = [
    { label: LICENSE_TYPES.TRIAL, value: 0 },
    { label: LICENSE_TYPES.SUBSCRIPTION, value: 1 },
    { label: LICENSE_TYPES.LIFETIME, value: 2 },
  ];

  const FORM_VALIDATION = Yup.object().shape({
    licenseName: Yup.string()
      .required("Required")
      .test("is-license-name-unique", "Already used", (value) => {
        return allLicensesForSelectedGroup.every(
          (license) => license.licenseName !== value
        );
      }),
    licenseKey: Yup.string()
      .required("Required")
      .matches(
        /^[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}$/,
        "Required format: XXXX-XXXX-XXXX-XXXX"
      )
      .test(
        "is-license-key-unique",
        "License Key is already in use",
        (value) => {
          return allLicenses.every((license) => license.licenseKey !== value);
        }
      ),
    licenseConfigurationName: Yup.string().required("Required"),
    licenseType: Yup.string().required("Required").nullable(),
    comment: Yup.string(),
    adminComment: Yup.string(),
    licenseValidFrom: Yup.date()
      .required("Valid From is required")
      .test(
        "is-before-license-valid-till",
        "Must precede the Valid Till",
        function (value) {
          const { licenseValidTill, licenseType } = this.parent;
          if (licenseType) {
            const licenseTypeNormalize = licenseTypes.find(
              (item) => item.label === licenseType
            ).label;
            const oneDayAfterValidTill = dayjs(licenseValidTill).add(
              12,
              "hour"
            );
            return licenseTypeNormalize === LICENSE_TYPES.LIFETIME
              ? true
              : dayjs(value).isBefore(oneDayAfterValidTill);
          }
        }
      ),
    licenseValidTill: Yup.date().typeError("Choose date").required("Required"),
  });

  return (
    <Formik
      initialValues={{
        licenseName: "",
        licenseKey: licenseKey,
        licenseConfigurationName: "",
        licenseType: "",
        licenseValidTill: dayjs().toDate(),
        licenseValidFrom: dayjs().toDate(),
        comment: "",
        adminComment: "",
      }}
      validationSchema={FORM_VALIDATION}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validateOnBlur
      validateOnChange
    >
      {(props) => (
        <Form>
          <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
            <DialogTitle>Add New License</DialogTitle>
            <DialogContent>
              <Stack
                direction="column"
                spacing={2}
                marginY={2}
                alignItems="start"
              >
                <FormikTextfield
                  name="licenseName"
                  fullWidth={true}
                  label="Name"
                  variant="standard"
                />
                {isAdmin && (
                  <FormikTextfield
                    name="licenseKey"
                    fullWidth={true}
                    label="Key"
                    variant="standard"
                  />
                )}
                <FormikSelect
                  name="licenseConfigurationName"
                  label="Configuration"
                  options={configurationsForSelectedGroup}
                  variant="standart"
                />
                <FormikSelect
                  name="licenseType"
                  label="Type"
                  options={LICENSE_TYPES_ARRAY}
                  variant="standart"
                />

                <FormikDateField
                  name="licenseValidFrom"
                  label="Valid From"
                  format="YYYY-MM-DD"
                />
                <FormikDateField
                  name="licenseValidTill"
                  label="Valid Till"
                  format="YYYY-MM-DD"
                  disabled={props.values.licenseType === LICENSE_TYPES.LIFETIME}
                />
                <FormikTextfield
                  name="comment"
                  fullWidth={true}
                  label="Comment"
                  variant="standard"
                  multiline
                />
                {isAdmin && (
                  <FormikTextfield
                    name="adminComment"
                    fullWidth={true}
                    label="Admin Comment"
                    variant="standard"
                    multiline
                  />
                )}
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button sx={{ color: "red" }} onClick={handleClose}>
                Cancel
              </Button>
              <FormikButton sx={{ color: "green" }} type="submit">
                Save
              </FormikButton>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};

export default LicenseAddDialog;
